<template>
  <div class="export mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1 class="display-2 mb-5">
            Export
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-card outlined>
            <v-card-title class="card-title-grey">
              Monatsabrechnung
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="auto">
                  <v-checkbox
                    v-model="all"
                    label="Alle Benutzer"
                    color="primary"
                    class="mt-1 mb-0"
                  />
                </v-col>
                <v-col cols="auto">
                  <div class="mt-2 mx-3 black--text font-weight-bold">oder</div>
                </v-col>
                <v-col>
                  <v-select
                    outlined
                    dense
                    :items="tenants"
                    v-model="tenantId"
                    label="Mandant"
                    item-text="text"
                    item-value="value"
                    :disabled="all"
                  ></v-select>
                </v-col>
              </v-row>
              <p>
                An welche Adresse soll der Bericht geschickt werden?
              </p>
              <v-text-field
                outlined
                dense
                v-model="email"
                label="E-Mail"
                type="email"
                :maxlength="255"
                :rules="emailRules"
              ></v-text-field>
              <v-btn
                color="primary"
                class="elevation-0"
                @click="exportData()"
                :disabled="valid"
              >
                <v-progress-circular
                  v-if="loading"
                  indeterminate
                  width="2"
                  size="16"
                  color="black"
                  class="mr-2"
                />
                Exportieren
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Export",

  data: () => ({
    all: false,
    tenants: [],
    loading: false,
    tenantId: null,
    email: null,
    emailRules: [
      v =>
        !v ||
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) ||
        "Ungültige E-Mail."
    ]
  }),

  computed: {
    ...mapState("user", ["user"]),

    valid() {
      return this.loading || !this.email || (!this.all && !this.tenantId);
    }
  },

  watch: {
    all() {
      this.tenantId = null;
    }
  },

  created() {
    this.email = this.user.email;
    this.fetchTenants();
  },

  methods: {
    fetchTenants() {
      this.$api.http
        .get(`backoffice/tenants?_per_page=99999`)
        .then(response => {
          this.tenants = response.data.data.map(tenant => {
            return {
              text: `${tenant.name}`,
              value: tenant.id
            };
          });
        });
    },

    exportData() {
      const url = this.all
        ? `backoffice/export/all`
        : `backoffice/export/tenant/${this.tenantId}`;
      this.loading = true;
      this.$api.http
        .post(url, {
          email: this.email
        })
        .then(response => {
          this.$toast.success(
            `Der Export wurde erstellt und an die Adresse ${this.email} verschickt.`
          );
        })
        .catch(error => {
          this.$toast.error("Der Export konnte nicht erstellt werden.");
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.export {
  .v-card {
    height: 100%;

    .card-title-grey {
      background-color: #eee;
      padding: 10px 15px;
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
}
</style>
